import React from "react";
import {Trans, useTranslation, I18nextContext} from 'gatsby-plugin-react-i18next';
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import {graphql} from 'gatsby';
import {StaticImage} from "gatsby-plugin-image";
import {returnLocaleURL} from "../../../utils/utils";
import {createMarkup} from "../../../utils/utils";
import Solution from "../../../components/solutions/Solution";

import CarouselWrapper from '../../../components/carousel/CarouselWrapper';
import Project01 from './images/project/02-2-project-01a.jpg'
import Project02 from './images/project/02-2-project-02a.jpg'
import Project03 from './images/project/02-2-project-03a.jpg'
import Project05 from './images/project/02-2-project-05a.jpg'
import Project07 from './images/project/02-2-project-07a.jpg'
import Project08 from './images/project/02-2-project-08a.jpg'
import Project09 from './images/project/02-2-project-09a.jpg'
import Project10 from './images/project/02-2-project-10a.jpg'
import dayUse from './images/applications/dayuse.jpg';
import rental from './images/applications/rentals.jpg';
import juice from './images/applications/juice.jpg';
import CustomerVideo from "../../../components/solutions/CustomerVideo";
import posterSolution from "./images/poster-solution.jpg";
import ElectronicLockLineup from "../../../components/solutions/ElectronicLockLineup";


const IndexPage = () => {

  const {t} = useTranslation();
  const context = React.useContext(I18nextContext);
  let sl = context.language;
  const projectImages = [
    {
      original: Project01,
      originalAlt: 'Locker Room Secured with Axis Locks at Let\'s Ride',
      thumbnailAlt: 'Locker Room Secured with Axis Locks at Let\'s Ride thumbnail',
      thumbnail: Project01,
      thumbnailLabel: 'Let\'s Ride',
      productLink: returnLocaleURL(sl, '/products/')
    },
    {
      original: Project02,
      originalAlt: 'Soul Cycle Locker Room Installed with Cue Electronic Locks',
      thumbnailAlt: 'Soul Cycle Locker Room Installed with Cue Electronic Locks thumbnail',
      thumbnail: Project02,
      thumbnailLabel: 'Soul Cycle',
      productLink: returnLocaleURL(sl, '/products/')
    },
    {
      original: Project03,
      originalAlt: 'Digilock 4G Electronic Locks at Croatia Fitness',
      thumbnailAlt: 'Digilock 4G Electronic Locks at Croatia Fitness thumbnail',
      thumbnail: Project03,
      thumbnailLabel: 'Croatia Fitness',
      productLink: returnLocaleURL(sl, '/products/')

    },
    {
      original: Project05,
      originalAlt: 'Athlete Factory keypad lockers',
      thumbnailAlt: 'Athlete Factory keypad lockers thumbnail',
      thumbnail: Project05,
      thumbnailLabel: 'Athlete Factory',
      productLink: returnLocaleURL(sl, '/products/')
    },
    // {
    //   original: Project06,
    //   originalAlt: 'Trango Towers dial combo lockers',
    //   thumbnailAlt: 'Trango Towers dial combo lockers thumbnail',
    //   thumbnail: Project06,
    //   thumbnailLabel: 'Trango Towers',
    //   productLink: returnLocaleURL(sl, '/products/')
    // },
    {
      original: Project07,
      originalAlt: 'The Ride House keypad lockers',
      thumbnailAlt: 'The Ride House keypad lockers thumbnail',
      thumbnail: Project07,
      thumbnailLabel: 'The Ride House',
      productLink: returnLocaleURL(sl, '/products/')
    },
    {
      original: Project08,
      originalAlt: 'UFC digilock keypad lockers',
      thumbnailAlt: 'UFC digilock keypad lockers thumbnail',
      thumbnail: Project08,
      thumbnailLabel: 'UFC',
      productLink: returnLocaleURL(sl, '/products/')
    },
    {
      original: Project09,
      originalAlt: 'Earth Treks Climbing Gym keypad lockers',
      thumbnailAlt: 'Earth Treks Climbing Gym keypad lockers thumbnail',
      thumbnail: Project09,
      thumbnailLabel: 'Earth Treks',
      productLink: returnLocaleURL(sl, '/products/')
    },
    {
      original: Project10,
      originalAlt: 'Roam Fitness keypad locks',
      thumbnailAlt: 'Roam Fitness keypad locks thumbnail',
      thumbnail: Project10,
      thumbnailLabel: 'Roam Fitness',
      productLink: returnLocaleURL(sl, '/products/')
    },
  ]
  const projectAsides = [
    '<span>france</span><br>' +
    '<h2>Let\'s Ride</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock 4G Keypad</a></p>',

    '<span>united states</span><br>' +
    '<h2>Soul Cycle</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock 4G Keypad</a></p>',

    '<span>Croatia</span><br>' +
    '<h2>Croatia Fitness</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock Cue Keypad</a></p>',

    '<span>Russia</span><br>' +
    '<h2>Athlete Factory</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock Axis Keypad</a></p>',

    // '<span>THE LOCATION</span><br>' +
    // '<h2>Trango Towers</h2>' +
    // '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock Dial Combo</a></p>',

    '<span>united states</span><br>' +
    '<h2>The Ride House</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock 4G Keypad</a></p>',

    '<span>United Kingdom</span><br>' +
    '<h2>UFC</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock Axis Keypad</a></p>',

    '<span>united states</span><br>' +
    '<h2>Earth Treks Climbing Gym</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock 4G Keypad</a></p>',

    '<span>united states</span><br>' +
    '<h2>Roam Fitness</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock 4G Keypad</a></p>'
  ]
  const ProjectAside = _ => <aside className={'dynamic-aside'}
                                   dangerouslySetInnerHTML={createMarkup(projectAsides[0])} />;
  const solutions = [
    {
      img: dayUse,
      label: 'day_use_lockers_gym',
      alt: 'day_use_lockers_gym',
      bg: 'light'
    },
    {
      img: rental,
      label: 'rental_lockers_gym',
      alt: 'rental_lockers_gym',
      bg: 'light'
    },
    {
      img: juice,
      label: 'juicebar_charging_lockers',
      alt: 'JuiceBar: Charging Gym Lockers',
      bg: 'light'
    }
  ]

  return (
      <Layout>
        <Seo title={t('solutions_digital_locker_locks_gyms')}
          description={t('des_solutions_digital_locker_locks_gyms')}
        />
        <div className="solutions">
          <section className="hero --hero-image no-pad">
            <StaticImage
                src="../../../images/industries/hero-health-fitness.jpg"
                quality={100}
                layout={'fullWidth'}
                formats={["auto", "webp", "avif"]}
                alt="Athlete Factory locker locks"
            />
            <h1>
              <Trans>
                Locker Locks
              </Trans>
              <span>
                <Trans>health_fitness_hero_subhead</Trans>
              </span>
            </h1>
          </section>
          <section className="customer-video healthcare">
            <div className="container">
              <div className={'grid-container'}>
                <h2><Trans>health_fitness_subhero_title</Trans></h2>
                <ul>
                  <li><Trans>health_fitness_subhero_list_l1</Trans></li>
                  <li><Trans>health_fitness_subhero_list_l2</Trans></li>
                  <li><Trans>health_fitness_subhero_list_l3</Trans></li>
                  <li><Trans>health_fitness_subhero_list_l4</Trans></li>
                  <li><Trans>health_fitness_subhero_list_l5</Trans></li>
                  <li><Trans>health_fitness_subhero_list_l6</Trans></li>
                </ul>
              </div>
              <div className="video-holder">
                <StaticImage
                    src="./images/healthandfitness.jpg"
                    quality={100}
                    width={508}
                    height={312}
                    formats={["auto", "webp", "avif"]}
                    loading={'lazy'}
                    alt="fitness"
                />
              </div>
            </div>
          </section>
          <ElectronicLockLineup
              label={'health_fitness_lineup_label'}
              showMech={true}
              showCurve={true}
              showAspire={true}
              showVersa={true}
              showCleo={true}
              showCTA={true}/>
          <section className="industry-carousel">
            <div className="container">
              <h2><Trans>health_fitness_carousel</Trans></h2>
              <CarouselWrapper
                  items={projectImages}
                  asides={projectAsides}
                  projectAside={<ProjectAside />}
                  showThumbnails={true}
                  showBullets={true}
                  dynamicAside={true}
                  allowImageClick={false}
              />
            </div>
          </section>
          <section className="customer-video healthcare">
            <CustomerVideo
                poster={posterSolution}
                title={'health_fitness_customer_video_title'}
                copy={'health_fitness_customer_video_copy'}
                vidURL={'https://cdn.mediavalet.com/usva/digilock/wPKl-bZodESn8pgc4NG-1A/1WG3QYG6VUKQ-WgJHTv0ig/Widescreen720p/health-fitness.mp4'}
            />
          </section>
          {/*<section className="industry-copy health-fitness">*/}
          {/*  <div className="container">*/}
          {/*    <p>*/}
          {/*      <Trans>health_fitness_copy</Trans>*/}
          {/*    </p>*/}
          {/*    <div className="video-holder">*/}
          {/*      <ReactVideo*/}
          {/*          poster={posterVideo}*/}
          {/*          vURL={'https://cdn.mediavalet.com/usva/digilock/yf45Cjnb-k-M96uZHjAiUA/o9qYXjW0dk6LXqgeXAH9xA/Widescreen720p/DigiLock_March_Update_English.mp4'}*/}
          {/*      />*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</section>*/}
          <section className="industry-uses">
            <div className="container">
              <h2><Trans>popular_keyless_gym</Trans></h2>
              <Solution items={solutions} />
            </div>
          </section>
        </div>
      </Layout>
  )
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
